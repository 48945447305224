<template>
  <div id="verification">
    <main>
      <h3 class="title">专业技术人员继续教育学时证明</h3>
      <el-descriptions title="当前证书信息" style="text-align: center;margin-top: 20px;" column=1>
        <el-descriptions-item labelStyle="width: 60px;text-align-last: justify" label="姓名">
          {{ certificateData.userName }}
        </el-descriptions-item>
        <el-descriptions-item label="身份证号">
          {{ certificateData.userCertificateNo }}
        </el-descriptions-item>
        <el-descriptions-item label="证书编号">
          {{ certificateData.certificateNo }}
        </el-descriptions-item>
        <el-descriptions-item labelStyle="width: 60px;text-align-last: justify" label="年度">
          {{ certificateData.certificateYearName }}
        </el-descriptions-item>
        <el-descriptions-item labelStyle="width: 60px;text-align-last: justify" label="培训学时">
          {{ certificateData.certificateDetails.totalCreditHours }}
        </el-descriptions-item>
        <el-descriptions-item label="签发单位">
          苏州电子信息技师学院
        </el-descriptions-item>
      </el-descriptions>
      <h4 style="margin-top: 20px;margin-bottom: 10px;">课程详情</h4>
      <el-table :data="certificateData.certificateDetails.courseMsgList" border  style="width: 100%">
        <el-table-column align="center" type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column align="center" prop="name" label="课程名称">
        </el-table-column>
        <el-table-column align="center" prop="creditHours" label="学时" width="50">
        </el-table-column>
      </el-table>
    </main>
  </div>
</template>
 
<script>
import { httpGet, httpNPGet, httpNPPost } from "@/utils/httpRequest";
export default {
  name: "verification",
  data() {
    return {
      certificateData:{

      }
      // tableData: [
      //   {
      //     name: "新中国70年：坚持和完善党的领导",
      //     hour: "1"
      //   },
      //   {
      //     name: "抗疫情专题课程",
      //     hour: "2"
      //   },
      //   {
      //     name: "民法典婚姻家庭编解读",
      //     hour: "2"
      //   },
      // ]
    }
  },
  methods: {
    getCertificateMsg(certificateNo,siteCode) {
      httpGet("/app/app-user-certificate/find/number",
        {
          certificateNo: certificateNo,
          siteCode: siteCode,
        }, (res) => {
          // console.log(res.data)
          this.certificateData = res.data
        }, (err) => {

        })
    }
  },
  mounted() {
    this.getCertificateMsg(this.$route.query.certificateNo,this.$route.query.siteCode)
    // console.log(this.$route.query.certificateNo,this.$route.query.siteCode)
    // console.log(this.$route)
  }
}
</script>
 
<style scoped lang="scss">
#verification {
  width: 100vw;
  height: 100vh;
  background-color: #fff;

  main {
    padding: 10px 30px;

    .title {
      padding: 5px 0px 0 0;
      width: 100%;
      text-align: center;
    }
  }

}
</style>